<template>
    <workspace-wrapper>
        <template #header>
            <default-slot-no-lines-header :bet="stateData.currentSpinResult.bet"
                                          :stake="stateData.currentSpinResult.stake"
            />

            <free-spins-slot-header v-if="stateData.currentSpinResult.isFreeSpin"
                                    v-bind="stateData.currentSpinResult.bonusFreeSpins"
            />
        </template>

        <template #workspace>
            <locked-symbols-slot-workspace :layout="stateData.currentSpinResult.layout"
                                           :symbols-path="symbolsPath"
                                           :lock-layout="stateData.currentSpinResult.lockedPositions"
            />
        </template>
    </workspace-wrapper>
</template>

<script>
import WorkspaceWrapper from "../Common/WorkspaceWrapper";
import DefaultSlotNoLinesHeader from "./Common/Header/DefaultSlotNoLinesHeader";
import FreeSpinsSlotHeader from "./Common/Header/FreeSpinsSlotHeader";
import LockedSymbolsSlotWorkspace from "@/components/Spins/Games/Slots/Common/LockedSymbolsSlotWorkspace";

export default {
    props: {
        stateData: {
            type: Object,
            required: true,
            default() {
                return {
                    currentSpinResult: {
                        balanceBefore: null,
                        bet: null,
                        stake: null,
                        layout: null,
                        lockedPositions: [],
                        bonusFreeSpins: {
                            totalWin: null,
                            totalFreeSpins: null,
                            currentFreeSpin: null,
                            freeSpinsAwardedCount: null
                        }
                    }
                }
            }
        }
    },
    data() {
        return {
            symbolsPath: 'fruitsanddiamonds'
        }
    },
    components: {
        LockedSymbolsSlotWorkspace,
        WorkspaceWrapper,
        DefaultSlotNoLinesHeader,
        FreeSpinsSlotHeader
    }
}
</script>

<style scoped>

</style>
